import React from "react";
import logo from "../../assets/imgs/LogoSeul.svg";
import diamondShape from "../../assets/imgs/diamondShape.png";
import pictoOne from "../../assets/imgs/pictoOne.png";
import pictoTwo from "../../assets/imgs/pictoTwo.png";
import pictoThree from "../../assets/imgs/pictoThree.png";
import pictoFour from "../../assets/imgs/pictoFour.png";
import pictoFive from "../../assets/imgs/pictoFive.png";
import pictoSix from "../../assets/imgs/pictoSix.png";
import pictoSeven from "../../assets/imgs/pictoSeven.png";
import pictoEight from "../../assets/imgs/pictoEight.png";
import pictoNine from "../../assets/imgs/pictoNine.png";
import pictoTen from "../../assets/imgs/pictoTen.png";
import pictoEleven from "../../assets/imgs/pictoEleven.png";
import pictoTwelve from "../../assets/imgs/pictoTwelve.png";
import elipse from "../../assets/imgs/ellipse.png";
import { Link } from "react-router-dom";
import portfolio from "../../assets/cv/CV_Hadrien_Villani.pdf";
import ContactComp from "../../components/contact/contact";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";

function Body() {
  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Portfolio Hadrien Villani</title>
          <link rel="canonical" href="https://www.hadrienvillani.fr/" />
        </Helmet>
      </HelmetProvider>
      <main>
        <section id="hero" className="hero">
          <div className="container">
            <div>
              <img src={logo} alt="logo" />
            </div>
            <div>
              <h1>
                Hadrien <span>Villani</span>
              </h1>
              <h4>Développeur full stack | Java Script | React & Node.js</h4>
              <p>
                Toujours en quête d'apprentissage, je suis à la recherche d'une
                alternance pour continuer à progresser en développement web et
                contribuer à des projets innovants. N'hésitez pas à me contacter
                si mon profil correspond à vos besoins !
              </p>
              <div className="flex-b container-social-media">
                <Link to={portfolio} target="_blank" title="Mon CV">
                  <button>
                    <FontAwesomeIcon className="icon" icon={faFilePdf} />
                    Mon cv
                  </button>
                </Link>
                <Link
                  target="_blank"
                  to="https://www.linkedin.com/in/hadrienvillani/"
                  title="Mon LinkedIn"
                >
                  <i className="fab fa-linkedin-in"></i>
                </Link>
                <Link
                  target="_blank"
                  to="https://github.com/HadrienVillani"
                  title="Mon GitHub"
                >
                  <i className="fab fa-github"></i>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section id="my-journey" className="my-journey">
          <div className="container">
            <div>
              <h2>Mon parcours</h2>
              <p>
                Développeur web full-stack en formation. Passionné par le
                développement web, je me spécialise dans le front-end et le
                back-end. Après trois années d'études en graphisme, j'ai choisi
                de me réorienter vers le développement web afin d'élargir mes
                compétences techniques. Actuellement étudiant à la 3W Academy,
                j'ai acquis des compétences en HTML/CSS, JavaScript, React, et
                Node.js. J'ai eu l'opportunité de travailler sur divers projets,
                disponibles plus bas. Toujours en quête d'apprentissage, je suis
                à la recherche d'une alternance pour continuer à progresser en
                développement full-stack et contribuer à des projets innovants.
                N'hésitez pas à me contacter si mon profil correspond à vos
                besoins !
              </p>
            </div>
            <div>
              <img src={diamondShape} alt="Mosaïque de losange" />
            </div>
          </div>
        </section>
        <section id="my-skills" className="my-skills">
          <div className="container">
            <h2>Mes compétences</h2>
            <div className="container-img flex-b center">
              <div>
                <img src={pictoEleven} alt="Logo de HTML 5" />
                <p>HTML 5</p>
              </div>
              <div className="flex-b dir-col">
                <img src={pictoOne} alt="Logo de CSS 3" />
                <p>CSS 3</p>
              </div>
              <div>
                <img src={pictoTwelve} alt="Logo de Java Script" />
                <p>JavaScript</p>
              </div>
              <div>
                <img src={pictoTwo} alt="Logo de Mongodb" />
                <p>Mongodb</p>
              </div>
              <div>
                <img src={pictoThree} alt="Logo de Docker" />
                <p>Docker</p>
              </div>
              <div>
                <img src={pictoFour} alt="Logo de Tailwinds" />
                <p>Tailwinds</p>
              </div>
              <div>
                <img src={pictoFive} alt="Logo de Node.js" />
                <p>Node.js</p>
              </div>
              <div>
                <img src={pictoSix} alt="Logo de Postman" />
                <p>Postman</p>
              </div>
              <div>
                <img src={pictoSeven} alt="Logo de Shopify" />
                <p>Shopify</p>
              </div>
              <div>
                <img src={pictoEight} alt="Logo de React" />
                <p>React</p>
              </div>
              <div>
                <img src={pictoNine} alt="Logo de Figma" />
                <p>Figma</p>
              </div>
              <div>
                <img src={pictoTen} alt="Logo de Next.js" />
                <p>Next.js</p>
              </div>
            </div>
          </div>
        </section>
        <section id="my-projects" className="my-projects">
          <div className="container">
            <h2>Mes projets</h2>
            <div className="flex-b">
              <Link className="item item-a" to="/mes-projets/projet_0">
                <div>Atélatoi</div>
              </Link>
              <Link className="item item-b" to="/mes-projets/projet_2">
                <div>Lapparra</div>
              </Link>
              <Link className="item item-c" to="/mes-projets/projet_4">
                <div>ÉcoSphère</div>
              </Link>
              <Link className="item item-d" to="/mes-projets/projet_3">
                <div>Cantaloube</div>
              </Link>
              <Link className="item item-e" to="/mes-projets/projet_5">
                <div>Artboost</div>
              </Link>
              <Link className="item item-f" to="/mes-projets/projet_6">
                <div>
                  Earthly
                  <br />
                  Haven
                </div>
              </Link>
              <Link className="item item-g" to="/mes-projets/projet_1">
                <div>
                  Refont
                  <br />
                  portfolio
                </div>
              </Link>
              <Link className="item item-h" to="/mes-projets/projet_7">
                <div>
                  L'Atelier
                  <br />
                  Fleuri
                </div>
              </Link>
            </div>
            <Link to={"/mes-projets"}>
              <button>Voir plus</button>
            </Link>
          </div>
        </section>
        {/* <section id="freelance" className="freelance">
                <div className="container">
                    <h2>Freelance</h2>
                    <p>Vous avez besoin d'une présence en ligne qui vous distingue et attire vos clients ? Mon service de
                        création de sites web répond à ce besoin en transformant votre vision en un site web captivant et
                        fonctionnel.</p>
                    <div>
                        <Link to='/freelance'>
                            <button>Voir plus</button>
                        </Link>
                    </div>
                </div>
            </section> */}
        <section id="my-trainings" className="my-trainings">
          <div className="container">
            <h2>Mes formations</h2>
            <ul>
              <li className="flex-b">
                <div>
                  <img src={elipse} alt="" />
                </div>
                <div>
                  <h4>2024 - 2026</h4>
                  <h3>3W Academy Formation Java Script fullstack</h3>
                  <p>
                    Formation en développement web. Contrat d’apprentissage -
                    Rythme : 3 semaines en entreprise / 1 semaine en école.
                    Formation éligible à l’Aide Exceptionnelle de 6000€.
                  </p>
                  <ul>
                    <li>
                      Maîtrise des technologies front-end : HTML, CSS,
                      JavaScript (ES6+), React
                    </li>
                    <li>
                      Développement back-end : Node.js, Express, gestion des
                      bases de données (MongoDB, MySQL)
                    </li>
                    <li>
                      Création d'API REST, gestion des sessions et sécurité
                    </li>
                    <li>Utilisation de Git et méthodologies agiles</li>
                    <li>
                      Déploiement d'applications web complètes (front-end +
                      back-end)
                    </li>
                  </ul>
                </div>
              </li>
              <li className="flex-b">
                <div>
                  <img src={elipse} alt="" />
                </div>
                <div>
                  <h4>2019-2021</h4>
                  <h3>Supdeweb - Bachelor web</h3>
                  <ul>
                    <li>
                      Renforcement des compétences en HTML, CSS et JavaScript
                    </li>
                    <li>Création de pages web statiques et dynamiques</li>
                    <li>
                      Conception d’interfaces utilisateur responsives
                      (mobile-first)
                    </li>
                    <li>Utilisation de frameworks CSS (Bootstrap, etc.)</li>
                    <li>Pratiques de versionnement de code avec Git</li>
                  </ul>
                </div>
              </li>
              <li className="flex-b">
                <div>
                  <img src={elipse} alt="" />
                </div>
                <div>
                  <h4>2018-2019</h4>
                  <h3>Hétic - Bachelor web</h3>
                  <ul>
                    <li>Compétences de base en HTML, CSS, JavaScript</li>
                    <li>Création de pages web dynamiques avec PHP</li>
                    <li>Création de pages web dynamiques avec PHP</li>
                    <li>Introduction aux API et gestion des formulaires</li>
                    <li>Utilisation de Git pour le versionnement de projets</li>
                    <li>Mise en place de sites web simples et fonctionnels</li>
                  </ul>
                </div>
              </li>
              <li className="flex-b">
                <div>
                  <img src={elipse} alt="" />
                </div>
                <div>
                  <h4>2015-2018</h4>
                  <h3>Écoles Assomption de Bondy - BAC PRO AMA CV</h3>
                  <ul>
                    <li>
                      Maîtrise des outils de création visuelle : Adobe
                      Photoshop, Illustrator, InDesign
                    </li>
                    <li>
                      Conception de maquettes et prototypes de sites web (UI/UX
                      design)
                    </li>
                    <li>
                      Création de chartes graphiques et d’identités visuelles
                    </li>
                    <li>
                      Conception de sites web responsives (mobile-first) avec
                      HTML et CSS
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </section>
        <section id="my-experiences" className="my-experiences">
          <div className="container">
            <h2>Mes expériences</h2>
            <div className="text-container">
              <div>
                <h5>2 ans de freelance 2022-2024</h5>
                <h3>Développeur web fullstack</h3>
                <h4>Freelance</h4>
                <p>
                  Après avoir suivi ma formation en tant que développeur
                  full-stack JavaScript, j'ai défini comme objectif la création
                  de plusieurs sites web from scratch pour mettre en pratique
                  mes compétences nouvellement acquises. Parallèlement, j'ai
                  entamé ma carrière en tant que freelance. Cette expérience m'a
                  permis d'acquérir une meilleure compréhension de
                  l'organisation des différentes phases d'un projet, ainsi que
                  de conseiller mes clients sur les aspects esthétiques et
                  techniques de leur site web.
                </p>
              </div>
              <div>
                <h5>6 mois de formation 2021-2022</h5>
                <h3>Formation full stack Java Script</h3>
                <h4>3W Academy</h4>
                <p>
                  Guidé par mon intérêt pour JavaScript, j'ai opté pour une
                  formation en tant que développeur full-stack JavaScript. Au
                  cours de cette période, j'ai eu l'occasion d'acquérir une
                  expertise dans l'utilisation de la bibliothèque React, de
                  développer mes premières opérations CRUD avec Node.js, et de
                  mettre en place mes premières bases de données avec MongoDB.
                </p>
              </div>
              <div>
                <h5>1 an en alternance en 2019-2020 </h5>
                <h3>Webdesigner / développeur Front-end</h3>
                <h4>Resource Lab - Agence de communication </h4>
                <p>
                  {" "}
                  J'ai réalisé ma dernière année d'études en alternance, au
                  cours de laquelle j'ai contribué au développement de
                  plateformes pour des entreprises spécialisées dans la mise en
                  relation entre recruteurs et candidats. Mon rôle m'a amené à
                  maîtriser l'outil Divi pour la création de thèmes WordPress,
                  ainsi qu'à concevoir des maquettes personnalisées pour nos
                  clients. Cette expérience professionnelle a considérablement
                  renforcé mes compétences. Cependant, j'ai décidé de recentrer
                  mon parcours vers le développement pur et de me concentrer sur
                  l'apprentissage de React.js.
                </p>
              </div>
              <div>
                <h5>3 mois de stage en 2018 </h5>
                <h3>Développeur Front-end</h3>
                <h4>CBI Multimedia - Agence de communication</h4>
                <p>
                  Ce stage m'a offert l'opportunité de mettre en pratique mes
                  compétences en HTML, CSS et JavaScript. J'ai également eu la
                  chance de coder un site web entièrement à partir de zéro et de
                  le mettre en ligne à la fin de mon stage. Cette expérience a
                  renforcé ma détermination à poursuivre une carrière dans ce
                  domaine.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section id="contact-me" className="contact-me">
          <h2>Me contacter</h2>
          <ContactComp />
        </section>
      </main>
    </div>
  );
}

export default Body;
