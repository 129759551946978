import React from "react";
import { Link } from "react-router-dom";
function Navbar() {
  return (
    <header className="flex-b header">
      <nav className="flex-b center">
        <ul className="flex-b">
          <li>
            <Link to={"/"} title="Accueil">
              Accueil
            </Link>
          </li>
          <li>
            <Link to={"/mes-projets"} title="Mes projets">
              Mes projets
            </Link>
          </li>
          {/* <li>
                        <Link to={'/freelance'}>Freelance</Link>
                    </li> */}
          <li>
            <Link to={"/contact"} title="Page de contact">
              contact
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Navbar;
