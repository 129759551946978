const projects = [
  {
    id: 'projet_0',
    title: 'Création du site Atélatoi',
    languages: ['React', 'HTML', "CSS", "Java Script", 'Tailwind CSS',"Shopify" ],
    picture_src: 'atelatoi.png',
    description:
      "Atélatoi, une société fondée par un ami, organise des rencontres sociales autour de plusieurs jeux de cartes spécialement conçus pour l’occasion. La première version du site, développée en React, visait à promouvoir ces événements avec une navigation fluide et un SEO optimisé pour maximiser la visibilité. Un an plus tard, l'entreprise a souhaité commercialiser ses jeux et m’a confié la transformation du site en boutique en ligne. Pour répondre à ce besoin, j'ai choisi Shopify, qui m’a permis de créer des fiches produits, un parcours d’achat intuitif, et d'intégrer une gestion des frais de transport basée sur le poids des produits. J'ai également adapté l'identité visuelle sur toutes les pages, défini la voix de la marque, et formé l'équipe à l'utilisation de Shopify ainsi que aux bonnes pratiques d'un site e-commerce.",
    button_text: 'Site Atélatoi',
    button_url: 'https://atelatoi.fr/',
    modal_button :"",
  },
  {
    id: 'projet_1',
    title: 'Refonte du portfolio',
    languages: ['React', 'JavaScript', 'HTML', 'CSS'],
    picture_src: 'RefontPortfolio.png',
    description:
      "Après plusieurs années avec le même portfolio, j’ai décidé de le refondre pour deux raisons principales : l’évolution de mes compétences en design, et mon objectif de présenter un profil plus expérimenté dans le cadre de ma recherche d’alternance. J'ai débuté par la création d’un mood board, inspiré de portfolios existants, afin de définir une direction claire pour le design. J'ai ensuite revu la charte graphique en m’assurant que les couleurs choisies respectaient l’accessibilité web, en utilisant des outils comme Adobe Color et Coolors. Pour le développement, j’ai opté pour React, car c’est la technologie que j’étudie en cours et avec laquelle je suis le plus à l'aise. Ce projet a aussi été l'occasion de m'entraîner à optimiser mon CSS sans Tailwind, en écrivant un fichier main.css le plus léger possible grâce à une factorisation minutieuse du code.",
    button_text: 'Mon portfolio',
    button_url: 'https://www.hadrienvillani.fr/',
    modal_button :"",
  },
  {
    id: 'projet_2',
    title: 'Création du site de Lapparra',
    languages: ['HTML', 'JavaScript', 'CSS'],
    picture_src: 'siteLapparra.png',
    description:
      "Mon premier projet en freelance a consisté à créer le site de Lapparra, une prestigieuse orfèvrerie parisienne fondée en 1893, célèbre pour ses coupes réalisées pour des compétitions sportives. Conçu en HTML, CSS et JavaScript, ce site de cinq pages retranscrit l’histoire et le savoir-faire de cette maison tout en intégrant une touche de modernité. Pour cela, j’ai eu l’opportunité de visiter leur bâtiment, ce qui m’a permis de comprendre l’identité de l’entreprise et d’adapter le design en conséquence.",
    button_text: 'Site Lapparra',
    button_url: 'http://www.lapparra.fr/',
    modal_button :"",
  },
  {
    id: 'projet_3',
    title: 'Refonte Cantaloube',
    languages: ['React', 'JavaScript', 'HTML', 'CSS'],
    picture_src: 'projetUn.png',
    description:
      "J'ai réalisé la refonte complète du site d'une bijouterie française, dirigée par un Meilleur Ouvrier de France, spécialisée dans la fabrication et la réparation de montres et de bijoux. Mon travail a commencé par la création des maquettes, avec une réflexion approfondie sur la meilleure manière de distinguer visuellement les sections bijouterie et horlogerie, tout en y intégrant une touche de modernité. Utilisant React pour le développement, j'ai repensé le design et l'expérience utilisateur pour mettre en avant le savoir-faire artisanal de l'entreprise. Cette approche a permis de créer une interface élégante, intuitive et fidèle à l'excellence des services proposés.",
    button_text: 'Site Cantaloube',
    button_url: 'https://hadrienvillani.github.io/Cantaloube/',
    modal_button :"",
  },
  {
    id: 'projet_4',
    title: 'Création du site ÉcoSphère',
    languages: ['Next.js','JavaScript', 'Tailwind CSS', 'MongoDB','Material UI'],
    picture_src: 'EcoSphere.PNG',
    description:
      "Ecosphere est un projet personnel que j'ai créé pour informer sur les bonnes pratiques écologiques, un sujet qui me tient à cœur. Le site propose divers outils, tels qu'une sélection d'applications écologiques, triées selon des critères spécifiques, et un agenda regroupant des événements français sur l'écologie. Développé en Next.js, ce projet m'a permis de découvrir cette technologie, à laquelle je me suis formé à travers des tutoriels et de la documentation. J'ai également utilisé Tailwind CSS pour ses avantages en termes de rapidité et de flexibilité. Le développement a commencé par la définition des fonctionnalités clés, suivie de la création d’un mood board et des maquettes, avec une attention particulière au logo, aux couleurs, et à la typographie pour refléter au mieux l'esprit du projet. Ce projet m'a offert une expérience concrète avec Next.js et Tailwind CSS, que je compte utiliser pour mes clients à l'avenir.",
    button_text: 'Site ÉcoSphère',
    button_url: 'https://eco-sphere.vercel.app/',
    modal_button :"",
  },
  {
    id: 'projet_5',
    title: 'Maquettes Artboost',
    languages: ['Figma','Illustrator'],
    picture_src: 'Artboost.png',
    description:
      "Pour le projet Artboost, j'ai conçu la maquette d’un site de financement participatif destiné à promouvoir les artistes et à les aider à financer leurs projets. L’objectif du site est de permettre aux artistes de présenter leurs créations afin de recevoir du soutien financier. En contrepartie, chaque donateur reçoit une récompense unique de la part de l’artiste, ce qui favorise un échange direct et personnel entre les créateurs et leurs mécènes. J'ai structuré le site en deux parties distinctes : une section dédiée aux donateurs et une autre aux artistes, afin de faciliter la navigation et d’optimiser l’expérience utilisateur pour chaque profil. Pour les donateurs, la maquette met en avant les projets des artistes, permettant une découverte simple et visuellement engageante. Les artistes, quant à eux, disposent d’une interface claire pour présenter leurs œuvres et gérer leurs campagnes de financement.En plus de cette séparation, j'ai intégré un système de recherche avancée qui permet de filtrer les projets par artiste ou par type d’art, rendant ainsi l'exploration plus intuitive et personnalisée pour les utilisateurs. Pour le design, j'ai choisi des éléments visuels qui reflètent la créativité et l'authenticité, en m’appuyant sur mes veilles graphiques quotidiennes pour donner au site une esthétique moderne et inspirante.",
    button_text: 'Site ÉcoSphère',
    button_url: '',
    modal_button :"Maquette complète",
    modal_picture :"ArtBoost_modal.jpg"
  },
  {
    id: 'projet_6',
    title: 'Maquettes Earthly Haven',
    languages: ['Figma','Illustrator'],
    picture_src: 'EarthlyHaven.png',
    description:
      "Pour ce projet, j'ai été chargé de concevoir la maquette d'un site e-commerce dédié aux cosmétiques bio et écoresponsables. J'ai commencé par une recherche approfondie, en analysant des sites concurrents et en m’appuyant sur les besoins spécifiques du client, ses produits ainsi que les particularités du secteur du bio. Cela m’a permis de poser des bases solides en identifiant les attentes du marché et en réfléchissant aux fonctionnalités adaptées. Ensuite, j'ai créé une charte graphique en respectant les couleurs du logo, afin de garantir une identité visuelle harmonieuse, en phase avec les valeurs écologiques de la marque. Pour la troisième étape, j’ai élaboré des wireframes pour définir la structure et la navigation du site. J'ai choisi de mettre en avant les produits pour ce projet, mais j'aurais pu également prioriser des fonctionnalités comme une barre de recherche ou une section dédiée aux actualités de l’entreprise, selon les objectifs. Les wireframes m'ont permis de visualiser l’agencement du contenu et de clarifier l’expérience utilisateur. Enfin,j’ai conçu le design du site en puisant dans mes veilles graphiques quotidiennes, ce qui m’a aidé à apporter une touche de modernité et à booster ma créativité. L’objectif était de réaliser un site attractif, aligné avec l’image écoresponsable de la marque, tout en assurant une navigation intuitive.",
    button_text: '',
    button_url: 'https://eco-sphere.vercel.app/',
    modal_button :"Maquette complète",
    modal_picture :"EarthlyHaven_modal.jpg",
  },
  {
    id: 'projet_7',
    title: "Création du site L'Atelier Fleuri",
    languages: ['React', 'JavaScript', 'HTML', 'CSS'],
    picture_src: 'atelierFleuri.png',
    description:
      "Dans le cadre d'un projet fictif, j'ai conçu un site pour un fleuriste, visant à mettre en avant ses bouquets et ses services. Le site offre une galerie des créations florales, où chaque bouquet est présenté avec ses tarifs. J'ai également intégré un slider de thématiques pour inspirer les visiteurs selon les saisons et événements, ainsi qu'une section dédiée à la localisation de la boutique pour faciliter l’accès des clients. Ce projet m'a permis de perfectionner l'affichage dynamique des produits et l'optimisation de l'expérience utilisateur sur un site commercial.",
    button_text: 'Site Fleuriste',
    button_url: 'https://hadrienvillani.github.io/l-atelier-floral/',
    modal_button :"",
  },
];

export default projects;
