import React, { useRef } from "react";
import data from "../../data/projects";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { faImage } from "@fortawesome/free-solid-svg-icons";

function ProjectDetails() {
  const { id } = useParams();
  let response = data.find((x) => x.id === `${id}`);
  let languages = response.languages;
  const modal_container = useRef(null);
  const modal_close = useRef(null);
  const project_page = useRef(null);
  function open() {
    modal_container.current.style.display = "flex";
    document.body.style.overflow = "hidden";
  }
  function close() {
    document.body.style.overflow = "auto";
    modal_container.current.style.display = "none";
  }

  return (
    <section className="flex-b dir-col project-details" ref={project_page}>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{response.title}</title>
          <link
            rel="canonical"
            href={`https://www.hadrienvillani.fr/mes-projets/` + response.id}
          />
        </Helmet>
      </HelmetProvider>
      <div className="container">
        <img
          src={window.location.origin + "/images/" + response.picture_src}
          alt=""
        />
        <h2>{response.title}</h2>
        <div className="language-container">
          <div className="flex-b container-subtitle">
            <div>
              <img src={require("../../assets/imgs/ellipse.png")} alt="" />
            </div>
            <h4>Langages</h4>
          </div>
          <ul className="flex-b">
            {languages.map((language, key) => {
              return <li key={key}>{language}</li>;
            })}
          </ul>
        </div>
        <div className="flex-b container-subtitle">
          <div>
            <img src={require("../../assets/imgs/ellipse.png")} alt="" />
          </div>
          <h4>Description du projet</h4>
        </div>
        <p>{response.description}</p>
        {response.modal_button !== "" ? (
          <div className="modal-container">
            <div className="modal-button-container">
              <button className="modal-button" onClick={open}>
                <FontAwesomeIcon icon={faImage} className="icon" />
                {response.modal_button}
              </button>
            </div>
            <div className="modal" ref={modal_container}>
              <div className="modal-container">
                <div className="modal-title-container">
                  <span ref={modal_close} onClick={close} className="close">
                    &times;
                  </span>
                  <h3>{response.title}</h3>
                </div>
                <img
                  src={
                    window.location.origin + "/images/" + response.modal_picture
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
        ) : (
          <Link target="_blank" to={response.button_url}>
            <button>
              <FontAwesomeIcon className="icon" icon={faGlobe} />
              {response.button_text}
            </button>
          </Link>
        )}
      </div>
      <Link to={"/mes-projets"}>
        <div className="flex-b back-to-projects">
          <div>
            <img src={require("../../assets/imgs/arrow-01.png")} alt="" />
          </div>
          <p>Retour aux projets</p>
        </div>
      </Link>
    </section>
  );
}

export default ProjectDetails;
