import './App.css';
import Body from './pages/Homepage/Body'
import Projects from './pages/Projects/Projects'
import Navbar from './components/NavBar/Navbar';
import Footer from './components/Footer/Footer'
import Contact from './pages/Contact/Contact';
import ProjectDetails from './pages/Projet/ProjectDetails';
import { BrowserRouter as Router, Route, useLocation, Routes } from 'react-router-dom';
import { useLayoutEffect } from 'react';
import Congrats from './pages/Congrats/Congrats';

const Wrapper = ({ children }) => {
  const location = useLocation();

  useLayoutEffect(() => {
    // Scroll to the top of the page when the route changes
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  }, [location.pathname]);

  return children;
};

function App() {
  
  return (
    <Router>    
      <Navbar/>
      <Wrapper>
        <Routes>
          <Route path="/mes-projets/:id" element={<ProjectDetails />} />
          <Route path="/" element={<Body />} />
          <Route path="/mes-projets" element={<Projects />}/>
          <Route path="/contact" element={<Contact />}/>
          <Route path="/remerciement" element={<Congrats />}/>
          <Route path="*" element={<Body/>}/>
        </Routes>
      </Wrapper>
      <Footer/>
    </Router>
  );
}

export default App;
