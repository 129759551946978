import React, { Fragment, useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";

function ContactComp() {
  const form = useRef();
  const refCaptcha = useRef();
  const alertMessage = useRef(null);
  const navigate = useNavigate();
  const [formValue, setFormValue] = useState({
    user_name: "",
    user_message: "",
    user_email: "",
  });

  const sendEmail = (e) => {
    e.preventDefault();
    const token = refCaptcha.current.getValue();
    const params = {
      ...formValue,
      "g-recaptcha-response": token,
    };
    switch (true) {
      case formValue.user_name === "":
        alertMessage.current.classList.add("failedMessage");
        alertMessage.current.innerHTML = "Nom & Prenom incorrectes";
        setTimeout(() => {
          alertMessage.current.classList.remove("failedMessage");
        }, 9000);
        break;

      case formValue.user_email === "":
        alertMessage.current.classList.add("failedMessage");
        alertMessage.current.innerHTML = "Email incorrecte";
        setTimeout(() => {
          alertMessage.current.classList.remove("failedMessage");
        }, 9000);
        break;

      case token === "":
        alertMessage.current.classList.add("failedMessage");
        alertMessage.current.innerHTML = "Captcha incorrect";
        setTimeout(() => {
          alertMessage.current.classList.remove("failedMessage");
        }, 9000);
        break;
      default:
        emailjs
          .send(
            "service_sk1vm61",
            "template_vjxrl6f",
            params,
            "3vQjRRNyqGZCS8-Ls"
          )
          .then((response) => {
            if (response.status === 200) {
              setFormValue({});
              navigate("/remerciement");
            } else {
              alertMessage.current.classList.add("failedMessage");
              alertMessage.current.innerHTML = "Une erreur est survenue";
              setTimeout(() => {
                alertMessage.current.classList.remove("failedMessage");
              }, 2000);
            }
          });
        break;
    }
  };
  return (
    <Fragment>
      <form className="flex-b dir-col" ref={form} onSubmit={sendEmail}>
        <div className="first-container flex-b">
          <div className="flex-b dir-col">
            <label htmlFor="name">Nom & Prénom</label>
            <input
              type="text"
              id="name"
              name="user_name"
              onChange={(e) =>
                setFormValue({ ...formValue, user_name: e.target.value })
              }
            />
          </div>
          <div className="flex-b dir-col">
            <label htmlFor="email">Email</label>
            <input
              id="email"
              type="email"
              name="user_email"
              onChange={(e) =>
                setFormValue({ ...formValue, user_email: e.target.value })
              }
            />
          </div>
        </div>
        <label htmlFor="message">Message</label>
        <textarea
          id="message"
          name="user_message"
          cols="30"
          rows="10"
          onChange={(e) =>
            setFormValue({ ...formValue, user_message: e.target.value })
          }
        ></textarea>
        <ReCAPTCHA
          ref={refCaptcha}
          sitekey={process.env.REACT_APP_SITE_KEY}
          className="flex-b center captcha"
        />
        <div className="flex-b center">
          <button type="submit">Envoyer</button>
        </div>
        <div className="alertMessage" ref={alertMessage}></div>
      </form>
    </Fragment>
  );
}

export default ContactComp;
