import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
function Congrats() {
  return (
    <section className="congratsPage">
      <div className="container flex-b center">
        <div className="flex-b center">
          <h2>Merci pour votre message</h2>
          <FontAwesomeIcon className="icon" icon={faCheck} />
        </div>
        <Link to={"/"}>
          <div className="flex-b back-to-home">
            <div>
              <img src={require("../../assets/imgs/arrow-01.png")} alt="" />
            </div>
            <p>Retour à l'accueil</p>
          </div>
        </Link>
      </div>
    </section>
  );
}

export default Congrats;
