import React from "react";
import { Link } from "react-router-dom";
import portfolio from "../../assets/cv/CV_Hadrien_Villani.pdf";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

function footer() {
  return (
    <footer id="footer" className="flex-b center dir-col">
      <div className="container">
        <div>
          <h4>En recherche d'alternance</h4>
          <p>
            Toujours en quête d'apprentissage, je suis à la recherche d'une
            alternance pour continuer à progresser en développement full-stack
            et contribuer à des projets innovants. N'hésitez pas à me contacter
            si mon profil correspond à vos besoins !
          </p>
        </div>
        <div className="flex-b dir-col">
          <Link
            className="button flex-b center"
            to="tel:0610646137"
            title="Mon numéro de téléphone"
          >
            <FontAwesomeIcon icon={faPhone} className="icon" />
            0610646137
          </Link>
          <Link
            to="mailto:hadrien.villani@live.fr"
            className="button"
            title="Mon adresse email"
          >
            <FontAwesomeIcon icon={faEnvelope} className="icon" />
            hadrien.villani@live.fr
          </Link>
          <Link
            to={portfolio}
            target="_blank"
            className="button"
            title="Mon Cv"
          >
            <FontAwesomeIcon className="icon" icon={faFilePdf} />
            Mon cv
          </Link>
        </div>
        <div>
          <h4>Navigation</h4>
          <nav>
            <ul>
              <li>
                <Link to="/" title="Accueil">
                  Accueil
                </Link>
              </li>
              <li>
                <Link to="/mes-projets" title="Mes projets">
                  Mes projets
                </Link>
              </li>
              <li>
                <Link to="/contact" tite="Page de contact">
                  Contact
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div>
        <ul className="social-media flex-b">
          <li className="jump">
            <Link
              target="_blank"
              to="https://www.linkedin.com/in/hadrienvillani/"
              title="Mon LinkedIn"
            >
              <i className="fab fa-linkedin-in"></i>
            </Link>
          </li>
          <li className="jump">
            <Link
              target="_blank"
              to="https://github.com/HadrienVillani"
              title="Mon GitHub"
            >
              <i className="fab fa-github"></i>
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default footer;
