import React from "react";
import ContactComp from "../../components/contact/contact";
import { Helmet, HelmetProvider } from "react-helmet-async";
function Contact() {
  return (
    <section id="contact-me" className="contact-me contact-me-page">
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Page de contact</title>
          <link rel="canonical" href="https://www.hadrienvillani.fr/contact" />
        </Helmet>
      </HelmetProvider>
      <h2>Me contacter</h2>
      <ContactComp />
    </section>
  );
}

export default Contact;
