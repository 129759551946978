import React from "react";
import { useNavigate } from "react-router-dom";
import data from "../../data/projects";
import { Helmet, HelmetProvider } from "react-helmet-async";

function Projects() {
  const navigate = useNavigate();
  return (
    <section className="my-projects-page">
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Mes projets</title>
          <link
            rel="canonical"
            href="https://www.hadrienvillani.fr/mes-projets"
          />
        </Helmet>
      </HelmetProvider>
      <div className="container">
        <h2>Mes projets</h2>
        <div className="container-my-project-page">
          {data.map((project, key) => {
            return (
              <div
                key={key}
                className="project"
                onClick={() => navigate(`${project.id}`)}
              >
                {" "}
                <h3>{project.title}</h3>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default Projects;
